import moment from 'moment'
import i18next from 'i18next'
import stringConstants from '../constants/strings'
import { SERVICES_TYPE } from '../constants/types'

export const factory = {
    createCustomerRegistration,
    createCustomer,
    createLogin,
    formatDate,
    parseJwt,
    createOpuser,
    createDriver,
    createVehicle,
    createOtp,
    createArea,
    createZone,
    createCircle,
    createService,
    reduceArray,
    reduceArrayProp,
    getLabelForName,
    _isInPolygon,
    createStops,
    checkTimesAfterMidth,
    createCalendar,
    createActivityJourney,
    createRequestTrip,
    existPointInStops,
    createActivityDriver,
    createActivityCustomer,
    createIncidence,
    createCustomerBilling,
    createHolderBilling,
    createLine,
    createLineWithSchedule,
    createExpedition,
    createTenant,
    createGetTripServices,
    createUpdateRequestForm,
    createItemMultipleTripResult
}

function parseJwt(token) {
    let base64Url = token.split('.')[1]
    let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
    let jsonPayload = decodeURIComponent(
        atob(base64)
            .split('')
            .map(function (c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
            })
            .join(''),
    )

    return JSON.parse(jsonPayload)
}

function reduceArray(array, field) {
    return array.reduce((acc, cur) => ({ ...acc, [cur[field]]: cur[field] }), {})
}

function reduceArrayProp(array, field, prop) {
    return array.reduce((acc, cur) => ({ ...acc, [cur[prop][field]]: cur[prop][field] }), {})
}

function getLabelForName(array, value) {
    let result = ''
    array.forEach(element => {
        if (element.id == value || element.Id == value) {
            result = element.Name || element.name
        }
    })
    return result
}

function createVehicle(shiftDays, id, brand, model, plateNumber, paxCapacity, luggageCapacity, wheelchairPlaces, otpId, returnToStart, otpName, shiftHours, initialPosition, vehiclePriority, descriptionCa, descriptionEs, descriptionEn) {
    const updatedshiftDays = shiftDays.map(s => {
        const beginningTime = moment(s.earliestStart, 'h')
        const endTime = moment(s.latestArrival, 'h')
        return {
            deviceDetails: s.deviceDetails,
            weekDays: s.weekDays,
            earliestStart: s.earliestStart,
            latestArrival: s.latestArrival,
            endsNextDay: endTime.isBefore(beginningTime),
            driverId: s.driverId,
            driverName: s.driverName,
            pushToken: s.pushToken,
            notificationLanguage: s.notificationLanguage,
            serviceId: s.serviceId,
            serviceName: s.serviceName,
        }
    })
    return {
        id: id,
        brand: brand.trim(),
        model: model.trim(),
        plateNumber: plateNumber.trim(),
        capacity: paxCapacity,
        luggageCapacity: luggageCapacity,
        wheelchairPlaces: wheelchairPlaces,
        vehiclePriority: parseInt(vehiclePriority),
        otpId: otpId,
        otpName: otpName,
        shiftDays: updatedshiftDays,
        initialPosition: {
            lat: initialPosition.lat,
            lon: initialPosition.long != null ? initialPosition.long : initialPosition.lon,
            address: initialPosition.address,
        },
        descriptionCa: descriptionCa,
        descriptionEs: descriptionEs,
        descriptionEn: descriptionEn,
        returnToStart: returnToStart,
    }
}

function createZone(id, name, points) {
    return {
        id: id,
        name: name.trim(),
        zoneType: 10,
        points: points,
    }
}

function createCustomerBilling(typology) {
    let result = []
    typology.forEach(item => {
        result.push({
            serviceId: item.serviceId,
            zoneRelationId: item.zoneRelationId,
            customerTypologies: item.customerBilling.customerTypologies,
            bonusCustomers: item.customerBilling.bonusCustomers,
        })
    })
    return result
}

function createHolderBilling(typology) {
    let result = []
    typology.forEach(item => {
        result.push({
            serviceId: item.serviceId,
            zoneRelationId: item.zoneRelationId,
            holderTariff: item.holderBilling.holderTariff,
            averagePrice: item.holderBilling.averagePrice,
            maxAmount: item.holderBilling.maxAmount,
        })
    })
    return result
}

function createCircle(id, name, lat, lng, radio) {
    return {
        id: id,
        name: name,
        zoneType: 20,
        circleInsert: {
            lat: lat,
            lon: lng,
            radius: radio,
        },
    }
}

function createArea(id, name, zones) {
    let zonesIds = zones.map(x => x.id)
    return {
        id: id,
        name: name.trim(),
        zones: zonesIds,
    }
}

function createService(
    id,
    name,
    otpId,
    otpName,
    zones,
    anticipationRequestTime,
    anticipationRequestTimeMax,
    notificationDeviationTimeMinutes,
    pickupDeviationTimeMinutes,
    assignedPickupDeviationTimeMinutes,
    cancelTime,
    maxAbsences,
    penaltyDays,
    additionalPaxs,
    isRestricted,
    hasZoneWithStops,
    authorizedCustomers,
    minimumAgeAuthorized,
    zonesCombination,
    editTripTimeMinutes,
    serviceType,
    restrictionMessageEs,
    restrictionMessageEn,
    restrictionMessageCa,
    maxTravelTimeFactor,
    absenceDaysExpiration,
    anticipationMinType,
    showPrice,
    showDriverInfo,
    assignmentMaxWaitingTimeMinutes,
    serviceTimePickUpSeconds,
    serviceTimeWheelChairSeconds,
    serviceTimeLuggageSeconds,
    updateMunicipalitiesAndRegions,
    pickupAndDropOffAutoComplete,
    alternativePickupTimeOffset,
    pricePerDistance,
    penaltiesEnabled,
    avoidDistanceMatrixCalculationService,
    driverStopDeviationMinutes
) {
    let zonesObject = {}
    zones.forEach(element => {
        zonesObject[element.id] = element.name
    })
    let zonesCombinationObject = []
    zonesCombination.forEach(element => {
        let itemZones = element.zones.split(',')
        itemZones.forEach(item => {
            let zoneItem = {
                originId: element.idZone,
                originName: zones.find(i => i.id == element.idZone).name,
                destinationId: item,
                destinationName: zones.find(i => i.id == item).name,
            }
            zonesCombinationObject.push(zoneItem)
        })
    })
    let authorizedCustomersObject = {}
    authorizedCustomers.forEach(element => {
        authorizedCustomersObject[element.id] = element.fullName
    })

    let serviceObj = {
        id: id,
        name: name.trim(),
        otpId: otpId,
        otpName: otpName,
        zones: zonesObject,
        zoneRelations: zonesCombinationObject,
        authorizedCustomers: authorizedCustomersObject,
        anticipationMinType: parseInt(anticipationMinType),
        anticipationMinRequestTime: parseInt(anticipationRequestTime),
        anticipationMaxRequestTimeDays: parseInt(anticipationRequestTimeMax),
        notificationDeviationTimeMinutes: parseInt(notificationDeviationTimeMinutes),
        pickupDeviationTimeMinutes: parseInt(pickupDeviationTimeMinutes),
        assignedPickupDeviationTimeMinutes: parseInt(assignedPickupDeviationTimeMinutes),
        cancellationTimeMinutes: parseInt(cancelTime),
        additionalPaxs: parseInt(additionalPaxs),
        maxAbsences: parseInt(maxAbsences),
        penaltyDays: parseInt(penaltyDays),
        editTripTimeMinutes: parseInt(editTripTimeMinutes),
        isRestricted: isRestricted,
        hasZoneWithStops: hasZoneWithStops,
        minimumAgeAuthorized: parseInt(minimumAgeAuthorized),
        serviceType: serviceType,
        restrictionMessageEs: restrictionMessageEs,
        restrictionMessageEn: restrictionMessageEn,
        restrictionMessageCa: restrictionMessageCa,
        maxTravelTimeFactor: maxTravelTimeFactor,
        absenceDaysExpiration: absenceDaysExpiration,
        showPrice: showPrice,
        showDriverInfo: showDriverInfo,
        assignmentMaxWaitingTimeMinutes: assignmentMaxWaitingTimeMinutes,
        serviceTimePickUpSeconds: serviceTimePickUpSeconds,
        serviceTimeWheelChairSeconds: serviceTimeWheelChairSeconds,
        serviceTimeLuggageSeconds: serviceTimeLuggageSeconds,
        updateMunicipalitiesAndRegions: updateMunicipalitiesAndRegions,
        pickupAndDropOffAutoComplete: pickupAndDropOffAutoComplete,
        alternativePickupTimeOffset: alternativePickupTimeOffset,
        pricePerDistance: parseFloat(pricePerDistance),
        penaltiesEnabled: penaltiesEnabled,
        driverStopDeviationMinutes: driverStopDeviationMinutes
    }
    if (parseInt(serviceType) === SERVICES_TYPE.regularWithSchedule) {
        serviceObj = { ...serviceObj, avoidDistanceMatrixCalculationService }
    }
    return serviceObj
}

function createOpuser(id, email, name, surname1, surname2, docNumber, phoneNumber, role, profileConfigId, tenantId, ftPhoneNumber = null, ownEmail = null, otpId = null, otpName = null, createOtp = false, otp) {
    return {
        id: id,
        email: email.trim(),
        name: name.trim(),
        surname1: surname1.trim(),
        surname2: surname2.trim(),
        identityDocumentCode: docNumber.trim(),
        phoneNumber: phoneNumber.trim(),
        lang: i18next.language,
        phoneNumberSecondary: ftPhoneNumber ? ftPhoneNumber.trim() : '',
        emailSecondary: ownEmail ? ownEmail.trim() : '',
        profile: parseInt(role),
        profileConfigId: profileConfigId,
        tenantId: tenantId,
        otpId: otpId != null ? otpId : null,
        otpName: otpName != null ? otpName : null,
        createOtp: createOtp,
        otp: createOtp
            ? {
                name: otp.name ? otp.name : '',
                vatNumber: otp.vatNumber ? otp.vatNumber : '',
                contactPerson: otp.contactPerson ? otp.contactPerson : '',
                phoneNumber: otp.phoneNumber ? otp.phoneNumber : '',
                email: otp.email ? otp.email : '',
            }
            : null,
    }
}

function createOtp(id, name, vatNumber, contactPerson, phoneNumber, email) {
    return {
        id: id,
        name: name.trim(),
        vatNumber: vatNumber.trim(),
        contactPerson: contactPerson.trim(),
        phoneNumber: phoneNumber.trim(),
        email: email.trim(),
    }
}

function createLogin(loginId, password) {
    return {
        email: loginId,
        password: password,
        returnSecureToken: true,
    }
}

function createDriver(id, email, name, surname1, surname2, identityDocument, otpId, phoneNumber, otpName, createOtp = false, otp) {
    return {
        id: id,
        email: email.trim(),
        name: name.trim(),
        surname1: surname1.trim(),
        surname2: surname2.trim(),
        identityDocumentCode: identityDocument.trim(),
        phoneNumber: phoneNumber.trim(),
        otpId: otpId != null ? otpId : null,
        otpName: otpName,
        createOtp: createOtp,
        otp: createOtp
            ? {
                name: otp.name ? otp.name : '',
                vatNumber: otp.vatNumber ? otp.vatNumber : '',
                contactPerson: otp.contactPerson ? otp.contactPerson : '',
                phoneNumber: otp.phoneNumber ? otp.phoneNumber : '',
                email: otp.email ? otp.email : '',
            }
            : null,
    }
}

function createCustomer(id, name, surname1, surname2, documentType, identityDocumentCode, email, phoneNumber, isPMR, isDisabled, hasWheelChair, birthDate, address, favouriteAddresses, prefix, countryCode) {
    return {
        id: id,
        name: name.trim(),
        fullSurname: surname1.trim() + ' ' + surname2.trim(),
        surname1: surname1.trim(),
        lang: i18next.language,
        surname2: surname2.trim(),
        birthDate: moment(birthDate).format('LL'),
        documentType: documentType,
        identityDocumentCode: identityDocumentCode.trim(),
        email: email.trim(),
        phoneNumber: phoneNumber.trim(),
        isPMR: isPMR,
        isDisabled: isDisabled,
        hasWheelChair: hasWheelChair,
        addressPosition: address.address != '' ? address : null,
        favouriteAddresses:
            favouriteAddresses && favouriteAddresses[0].addressPosition.address != ''
                ? favouriteAddresses
                : [],
        prefix: prefix,
        countryCode: countryCode
    }
}

function createRequestTrip(
    customerId,
    locationStart,
    locationFinish,
    isRequestByDropOff,
    pickupStartTime,
    numPassengers,
    luggage,
    hasWheelChair,
    addressStart,
    addressFinish,
    customer,
    langNotification,
    pushToken,
    passengerId = null,
    pickUpStopName,
    dropOffStopName,
    serviceLineDirection = null,
    isReturn = false,
    originalTripId = null,
    outboundTripId = null,
) {

    const obj = {
        customerId: customerId,
        pickUpLocation: {
            lat: locationStart.lat,
            lon: locationStart.lng,
            address: addressStart,
            id: locationStart.id,
        },
        dropOffLocation: {
            lat: locationFinish.lat,
            lon: locationFinish.lng,
            address: addressFinish,
            id: locationFinish.id,
        },
        isRequestByDropOff: isRequestByDropOff,
        requestPickUpStartTime: moment(pickupStartTime),
        pickupEndTime: moment(pickupStartTime).add(15, 'minutes'),
        numPassengers: numPassengers,
        customerExpoPushToken: pushToken,
        customerNotificationLanguage: langNotification,
        luggage: parseInt(luggage),
        hasWheelChair: hasWheelChair,
        customerDateOfBirth: customer.isOnRelatedCustomerBehalf ? customer.parentBirthDate : customer.birthDate,
        customerName: customer.isOnRelatedCustomerBehalf ? customer.customerParentName : customer.name,
        isOnRelatedCustomerBehalf: customer.isOnRelatedCustomerBehalf,
        relatedCustomer: customer.isOnRelatedCustomerBehalf
            ? {
                id: customer.customerIdRelated,
                name: customer.name,
                dateOfBirth: customer.birthDate,
            }
            : null,
        passengerId: passengerId,
        pickUpStopName: pickUpStopName != '' ? pickUpStopName : '',
        dropOffStopName: dropOffStopName != '' ? dropOffStopName : '',
        serviceLineDirection: serviceLineDirection,
        isReturn: isReturn,
    }

    if (originalTripId) {
        obj.originalTripId = originalTripId
    }

    if (outboundTripId) {
        obj.outboundTripId = outboundTripId
    }

    return obj
}

function existPointInStops(point, stops) {
    const result = stops.filter(x => x.point.lat == point.lat && x.point.lon == point.lon)
    return result.length > 0 ? true : false
}

function createCustomerRegistration(email, password, firstName, surname1, document, gdprAccepted) {
    return {
        username: email,
        password: password,
        name: firstName,
        surname1: surname1,
        identityDocumentCode: document,
        gdprAccepted: gdprAccepted,
    }
}

function formatDate(date, format) {
    let minDate = moment.utc('0001-01-01') // minimum value as per UTC

    let receiveDate = moment(date)
    if (moment.utc(receiveDate).isAfter(minDate)) {
        return receiveDate.format(format)
    } else {
        return ''
    }
}

function _isInPolygon(point, polygonArray) {
    let x = point.lat
    let y = point.lng ? point.lng : point.lon
    let inside = false
    for (let i = 0, j = polygonArray.length - 1; i < polygonArray.length; j = i++) {
        let xLat = polygonArray[i].lat
        let yLat = polygonArray[i].lon
        let xLon = polygonArray[j].lat
        let yLon = polygonArray[j].lon
        let intersect = yLat > y !== yLon > y && x < ((xLon - xLat) * (y - yLat)) / (yLon - yLat) + xLat
        if (intersect) inside = !inside
    }
    return inside
}

function createStops(array) {
    let stops = []
    array.forEach(element => {
        stops.push({
            id: isNaN(element.id) ? element.id : '',
            name: element.name.trim(),
            address: element.address.trim(),
            point: element.point,
            isBreakStop: element.isBreakStop,
            isHandoverStop: element.isHandoverStop
        })
    })
    return stops
}

function createActivityJourney(userName, userId, userType, body) {
    return {
        userName: userName,
        userId: userId,
        userType: userType,
        body: body,
    }
}

function createActivityDriver(userName, userId, userType, body) {
    return {
        userName: userName,
        userId: userId,
        userType: userType,
        body: body,
    }
}

function createActivityCustomer(userName, userId, userType, body) {
    return {
        userName: userName,
        userId: userId,
        userType: userType,
        body: body,
    }
}

function createCalendar(excludedPeriods, requestShiftDays, shiftDays, workingBankHolidays, shiftBreaks, hasRestriction) {
    const updatedshiftDays = shiftDays.map(s => {
        const beginningTime = moment(s.earliestStart, 'h')
        const endTime = moment(s.latestArrival, 'h')
        return {
            weekDays: s.weekDays,
            earliestStart: s.earliestStart,
            latestArrival: s.latestArrival,
            endsNextDay: endTime.isBefore(beginningTime),
        }
    })

    const updatedshiftBreaks = shiftBreaks.map(s => {
        const beginningTime = moment(s.shiftDays.earliestStart, 'h')
        const endTime = moment(s.shiftDays.latestArrival, 'h')
        return {
            shiftDays: {
                weekDays: s.shiftDays.weekDays,
                earliestStart: s.shiftDays.earliestStart,
                latestArrival: s.shiftDays.latestArrival,
                endsNextDay: endTime.isBefore(beginningTime),
            },
            stop: s.stop
        }
    })

    const updatedWorkingBankHolidays = workingBankHolidays.map(w => {
        let shiftHours = []

        w.shiftHours.map(s => {
            const beginningTime = moment(s.earliestStart, 'h')
            const endTime = moment(s.latestArrival, 'h')
            shiftHours.push({
                earliestStart: s.earliestStart,
                latestArrival: s.latestArrival,
                endsNextDay: endTime.isBefore(beginningTime),
            })
        })
        w.shiftHours = shiftHours
        return w
    })

    return {
        requestShiftDays: hasRestriction ? requestShiftDays : [],
        shiftDays: updatedshiftDays,
        shiftBreaks: updatedshiftBreaks,
        excludedPeriods: excludedPeriods ? excludedPeriods : [],
        workingBankHolidays: updatedWorkingBankHolidays,
    }
}

function checkTimesAfterMidth(earliestStart, latestArrival) {
    let start = moment(earliestStart, 'HH:mm')
    let end = moment(latestArrival, 'HH:mm')
    return end.isBefore(start)
}

function createIncidence(incidence) {
    const address = incidence.location.address
    return {
        subject: incidence.subject,
        body: incidence.body,
        location: {
            ...incidence.location,
            address,
        },
        priority: incidence.priority,
        ticketStatus: incidence.ticketStatus,
        cause: incidence.cause,
        customerId: incidence.customerId,
        customerName: incidence.customerName,
        images: incidence.images,
    }
}

function createLine(name, vehicle, serviceId, outbound, returnL) {
    return {
        name: name,
        serviceId: serviceId,
        vehicleId: vehicle,
        outbound: {
            zoneStops: Array.isArray(outbound.zoneStops[0]) ? outbound.zoneStops[0].map(item => item) : outbound.zoneStops,
            allowOppositeDirections: outbound.allowChangeDirection,
            calendar: outbound.calendar,
        },
        return: {
            zoneStops: Array.isArray(returnL.zoneStops[0]) ? returnL.zoneStops[0].map(item => item) : returnL.zoneStops,
            allowOppositeDirections: returnL.allowChangeDirection,
            calendar: returnL.calendar,
        },
    }
}

function createLineWithSchedule(name, vehicles, serviceId, outbound, numExpeditions, bankHolidaysLine) {
    return {
        name: name,
        numExpeditions: parseInt(numExpeditions),
        serviceId: serviceId,
        vehicleIds: vehicles,
        bankHolidaysLine: bankHolidaysLine,
        outbound: {
            zoneStops: Array.isArray(outbound.zoneStops[0]) ? outbound.zoneStops[0].map(item => item) : outbound.zoneStops
        }
    }
}

function createExpedition(expeditions) {
    return {
        expeditions: expeditions,
    }
}

function createTenant(id, name, identityDocumentCode, contact, email, accessDomain, subDomainAdmin, subDomainCustomers, primaryColor, secondaryColor, image) {
    return {
        id: id,
        name: name.trim(),
        identityDocumentCode: identityDocumentCode.trim(),
        contact: contact.trim(),
        email: email.trim(),
        accessDomain: accessDomain.trim(),
        subDomainAdmin: subDomainAdmin.trim(),
        subDomainCustomers: subDomainCustomers.trim(),
        primaryColor: primaryColor,
        secondaryColor: secondaryColor,
        base64Favicon: image,
    }
}

function createGetTripServices(trip, pushToken, customerDateOfBirth, newTime, isReturn, outboundTripId, isMultipleRequest, isRequestByDropOff) {
    return {
        ...createGetTripServicesBase(trip, pushToken, customerDateOfBirth, newTime, isReturn, outboundTripId, isMultipleRequest, isRequestByDropOff),
        pickUpLocation: { ...trip.dropOffLocation },
        dropOffLocation: { ...trip.pickUpLocation },
    }
}

function createGetTripServicesBase(trip, pushToken, customerDateOfBirth, newTime, isReturn, outboundTripId, isMultipleRequest, isRequestByDropOff) {
    const newMoment = moment(newTime, 'HH:mm')
    return {
        customerId: trip.customerId.slice(),
        requestPickUpStartTime: moment(trip.requestPickUpStartTime).set({ hours: newMoment.hours(), minutes: newMoment.minutes() }).toDate(),
        numPassengers: trip.numPassengers,
        customerExpoPushToken: pushToken.slice(),
        customerNotificationLanguage: null,
        luggage: trip.luggage,
        hasWheelChair: trip.hasWheelChair,
        customerDateOfBirth: customerDateOfBirth.slice(),
        customerName: trip.customerName?.slice(),
        isOnRelatedCustomerBehalf: trip.isOnRelatedCustomerBehalf,
        relatedCustomer: trip.relatedCustomerId?.slice(),
        isReturn: isReturn,
        outboundTripId: outboundTripId,
        isMultipleRequest: isMultipleRequest,
        originalTripId: trip.originalTripId,
        isRequestByDropOff: isRequestByDropOff
    }
}

function createUpdateRequestForm(trip, pushToken, customerDateOfBirth, newTime, isReturn, outboundTripId, isMultipleRequest, isRequestByDropOff) {
    return {
        ...createGetTripServicesBase(trip, pushToken, customerDateOfBirth, newTime, isReturn, outboundTripId, isMultipleRequest, isRequestByDropOff),
        pickUpLocation: {
            desc: {
                description: trip.pickUpLocation.address
            },
            location: { lat: trip.pickUpLocation.lat, lon: trip.pickUpLocation.lon }
        },
        dropOffLocation: {
            desc: {
                description: trip.dropOffLocation.address
            },
            location: { lat: trip.dropOffLocation.lat, lon: trip.dropOffLocation.lon }
        }
    }
}

function createItemMultipleTripResult(trip) {
    return {
        apiErrors: [],
        hasErrors: false,
        pickupHours: [],
        requestPickUpTime: trip.requestPickUpStartTime,
        tripResponse: trip
    }
}